<template>
  <div class="Policy">
    <p class="top_title">RealPlayer播放器订购条款</p>
    <p class="content">最后修改日期：2022年4月30日</p>
    <p class="title">一般条款</p>
    <p class="content">我们提供免费和付费版本的RealPlayer播放器服务。RealPlayer的某些高级功能需要高级帐户，可以通过应用内购买或通过realpalyer.cn购买。您也可以通过第三方的应用商店购买高级帐户，在这种情况下，您的购买也受该应用商店服务条款的约束。我们保留随时更改服务或任何高级功能的未来费用的权利，也同时保留向您发送相应通知终止服务的权利。</p>
    <p class="title">免费帐户</p>
    <p class="content">您理解并同意，RealNetworks可能免费授予您应用的使用许可，但不应视为RealNetworks放弃向您收费的权利。免费账户的用户可以按需购买升级高级版本，使用免费账户即表示您同意本订阅条款。</p>
    <p class="title">高级账户</p>
    <p class="content">RealNetworks为您提供了支付高级帐户的选项，以访问高级功能并增加您的云存储空间。通过注册RealPlayer播放器的高级帐户，您同意该等订阅条款。</p>
    <p class="title">账单</p>
    <p class="content">对于高级帐户订阅，RealNetworks将自您购买之日起每月或每年自动向您的信用卡收费，具体取决于您选择的期限（加上所适用的税费），除非您取消您的帐户。如果未及时支付任何费用，或RealNetworks无法处理您的交易，RealNetworks保留将RealPlayer播放器高级帐户调整为免费帐户的权利。在这种情况下，在一段合理的时间内，您仍然可以访问您的内容，但如果您超过了免费存储限制，您可能无法上传额外的内容；RealNetworks可能会在不通知您的情况下删除与已取消、未使用的免费或过期的高级帐户相关的内容。所有费用均不可退还，对于已使用的部分期间的费用也不可退还、不可抵免。信用卡付款详细信息可通过“帐户设置–账单”选项卡访问。</p>   
    <p class="title">取消你的帐户</p>
    <p class="content">您可以随时从“帐户设置–计划”选项卡取消RealPlayer高级帐户，取消将在当前计费周期结束时生效。在计费周期结束之前，您仍然可以访问高级功能。</p>   
    <p class="content">© 2022 RealNetworks 北京瑞尔视科技有限公司 版权所有</p>
  </div>
</template>
<script>
  export default {
    name: 'Policy',
    created:function(){
        window.scrollTo(0,0);
    },
    methods:{
      
    }
  }
</script>
<style scoped>
  .Policy{width:60%;margin: 0 auto;}
  .top_title{font-size: 28px;color: #00a7e1;margin: 20px 0;font-weight: bold;}
  .title{font-size: 20px;font-weight: bold;margin: 10px 0;}
  .content,th,td{text-indent:2em;font-size: 14px;line-height: 28px;}
  ul{margin-left: 40px;}
  li{font-size: 14px;line-height: 28px;}
  table{border-top: 1px solid #333333;margin: 20px 0;border-left: 1px solid #333333;}
  th{font-size: 14px;}
  th,td{border-right: 1px solid #333;border-bottom: 1px solid #333;padding: 20px;}
</style>